export const textOnly = (e) => {
    let value = e.target.value;
    const expression = /[^A-Za-z]/;
    return expression.exec(value);
};

export const numberOnly = (e) => {
    let value = e.target.value;
    const expression = /[^0-9]/;
    return expression.exec(value);
};

export const textWithSpaces = (e) => {
    let value = e.target.value;
    const expression = /[^A-Za-z- ]/;
    return expression.exec(value);
};

export const firstLetterUpperCase = (text) => {
    if (!text) return "";
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
};

export const toTitleCase = (text) => {
    if (!text) return "";
    let string = "",
        arr = text.split(" ");
    for (let word of arr) {
        if (word) {
            string += word[0].toUpperCase();
            string += word.substr(1).toLowerCase();
            string += " ";
        }
    }
    return string.trim();
};

export const toEqualSpaces = (text, spaces = 1) => {
    if (!text) return "";
    let string = "",
        arr = text.split(" ");
    for (let word of arr) {
        if (word) {
            string += word;
            for (let i = 0; i < spaces; i++) string += " ";
        }
    }
    return string.trim();
};