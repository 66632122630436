import React, { Component } from "react";
import uniquid from "uniqid";
import { postRequest } from "../../services/HttpService.js";
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import {
  IncompleteLoginForm,
  LoggingInWait,
  LoginFailed,
} from "../../config/LoginConstants";
import {
  CheckingInputs,
  DoNotRefresh,
  PleaseWait,
  ProcessFailed,
  ServerError,
  UnexpectedError,
  ValidationError,
} from "../../config/CommonConstants";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
    };
    this.checkIfThereIsOnGoingSession();
    this.checkIfAlreadyLoggedIn();
  }

  checkIfThereIsOnGoingSession = () => {
    if (localStorage.getItem("onboardingSession"))
      this.props.history.replace("/onboarding-questionnaire");
  };

  checkIfAlreadyLoggedIn = () => {
    let token = localStorage.getItem("token");
    if (token) return this.props.history.replace("/home");
  };

  unsetBusyState = () => {
    this.setState({ showLoading: false, loginFormOpacity: 1 });
  };

  allowEmailChars = (e) => {
    let value = e.target.value;
    let { email } = this.state;
    let regex = /\s/;
    if (!regex.exec(e.target.value)) {
      email = value;
      return this.setState({ email: email });
    }
  };

  setPassword = (e) => {
    let { password } = this.state;
    let regex = /\s/;
    if (!regex.exec(e.target.value)) {
      password = e.target.value;
      return this.setState({ password: password });
    }
  };

  validateForm = () => {
    let { email, password } = this.state;
    if (email.length && password.length) {
      return false;
    }
    return true;
  };

  login = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
      loadingTitle: PleaseWait,
      loadingDescription: CheckingInputs,
      extraInfo: DoNotRefresh,
      showLoadingSpinner: true,
      loginFormOpacity: 0.3,
      showCloseButton: false,
    });
    let validation = this.validateForm();
    if (validation)
      this.setState({
        showLoading: true,
        loadingTitle: LoginFailed,
        loadingDescription: IncompleteLoginForm,
        extraInfo: ValidationError,
        showLoadingSpinner: false,
        showCloseButton: true,
      });
    this.setState({
      showLoading: true,
      loadingTitle: PleaseWait,
      loadingDescription: LoggingInWait,
      extraInfo: DoNotRefresh,
      showLoadingSpinner: true,
      loginFormOpacity: 0.3,
      showCloseButton: false,
    });
    try {
      let request = {
        payload: { email: this.state.email, password: this.state.password },
        options: { uri: "/login" },
      };
      let {
        result: data,
        code,
        message,
      } = await postRequest("/auth", request, uniquid());
      if (code && code !== 200)
        return this.setState({
          showLoading: true,
          loadingTitle: LoginFailed,
          loadingDescription: message,
          extraInfo: ServerError,
          showLoadingSpinner: false,
          showCloseButton: true,
        });

      localStorage.setItem("token", data.token);
      return this.props.history.replace("/home");
    } catch (error) {
      this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="container mt-5 pt-5"
          style={{ opacity: this.state.loginFormOpacity }}
        >
          <div>
            <h1 className="font-weight-bold brand-color text-center">
              <img alt="" src="/assets/logo.webp" width="120px" className="ml-1" />
            </h1>
          </div>
          <div className="row">
            <h3 className="text-center text-primary w-100">Login Console</h3>
          </div>
          <form onSubmit={this.login}>
            <div className="form-group">
              <label htmlFor="loginEmail">Your Username *</label>
              <input
                type="text"
                className="form-control"
                id="loginEmail"
                aria-describedby="emailError"
                placeholder="Fill Your Username"
                value={this.state.email}
                onChange={this.allowEmailChars}
              />
              <small
                id="emailError"
                className="form-text text-danger font-weight-bold h6"
              >
                You were alloted this when you completed your assessment !
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="loginPassword">Your Password *</label>
              <input
                type="password"
                className="form-control"
                id="loginPassword"
                placeholder="Fill Your Password"
                value={this.state.password}
                onChange={this.setPassword}
              />
              <small
                id="passwordError"
                className="form-text text-danger font-weight-bold h6"
              >
                Your Secret Password (Without space)!
              </small>
            </div>
            <div className="row text-center p-4">
              <button
                type="submit"
                className="btn btn-primary ml-auto mr-auto w-50 p-2"
                disabled={this.validateForm()}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    );
  }
}

export default LoginComponent;
