import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import uniquid from "uniqid";

import ApplicationBlocker from "../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../services/HttpService";
import {
  ProcessFailed,
  UnexpectedError,
  TokenExpired,
} from "../config/CommonConstants";

import ProfileComponent from "../components/profile/ProfileComponent";
import AssessmentComponent from "../components/assessment/AssessmentComponent";
import FooterComponent from "./FooterComponent";
import NavbarComponent from "./NavbarComponent";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkAuthorization();
  }

  checkAuthorization = () => {
    if (!localStorage.getItem("token"))
      return this.props.history.replace("/intro");
  };

  setLoading = (
    title,
    description,
    moreInfo,
    closeButton,
    showLoadingSpinner
  ) => {
    return this.setState({
      opacity: 0.3,
      showLoading: true,
      showCloseButton: closeButton,
      loadingTitle: title,
      loadingDescription: description,
      extraInfo: moreInfo,
      showLoadingSpinner: showLoadingSpinner,
    });
  };

  unsetBusyState = async () => {
    if (this.state.redirect && this.state.redirect.length) {
      localStorage.clear();
      return this.props.history.replace(this.state.redirect);
    }
    return this.setState({ showLoading: false, opacity: 1, redirect: "" });
  };

  async componentDidMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      return this.props.history.replace("/intro");
    }
    try {
      let {
        result: data,
        code,
        message,
      } = await postRequest(
        "/auth",
        {
          payload: { token: token },
          options: { uri: "/verify-user" },
        },
        uniquid()
      );
      if (code >= 300) {
        localStorage.clear();
        return this.setState({
          showLoading: true,
          loadingTitle: ProcessFailed,
          loadingDescription: message,
          extraInfo: TokenExpired,
          showLoadingSpinner: false,
          showCloseButton: true,
          redirect: "/intro",
        });
      }
      return this.setState({ user: data.user, token: token, redirect: "" });
    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <NavbarComponent user={this.state.user} />
          <div style={{ position: "relative", top: "4rem" }}>
            <Switch>
              <Route exact path="/home/profile" component={ProfileComponent} />

              <Route exact path="/home/assessment" component={AssessmentComponent} />

              <Route exact path="/home" component={ProfileComponent} />

              <Route path="/*" component={() => <Redirect to="/home" />} />
            </Switch>
          </div>
        </div>
        <FooterComponent />
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HomeComponent;
