export const ValidationError = "Validation Error !";
export const IncompleteData = "Incomplete Data !";
export const PleaseWait = "Please Wait !";
export const Initializing =
  "Initializing new onboarding process for you, it may take a while !";
export const DoNotRefresh = "Don't Refresh !";
export const ServerError = "Server Error !";
export const ProcessFailed = "Failed ...";
export const UnexpectedError = "Unexpected Error !";
export const Success = "Success ...";
export const ProcessingOnboarding =
  "We are processing your onboarding session, it may ask some details (typically 5) to assist you better, please answer these details patently !";
export const CheckingInputs =
  "We are verifying your credentials with server, it may take a while !";
export const TokenExpired = "Token is expire, requires re-login !";
