import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";

import "./App.css";
import HomeComponent from "./components/HomeComponent";
import OnboardingComponent from "./components/onboarding/OnboardingComponent";
import IntroductionComponent from "./components/onboarding/IntroductionComponent";
import QuestionnaireComponent from "./components/onboarding/QuestionnaireComponent";
import SignUpComponent from "./components/signup/SignUpComponent";
import LoginComponent from "./components/login/LoginComponent";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/intro" component={IntroductionComponent} />
          <Route exact path="/onboarding" component={OnboardingComponent} />
          <Route
            exact
            path="/onboarding-questionnaire"
            component={QuestionnaireComponent}
          />
          <Route exact path="/sign-up" component={SignUpComponent} />
          <Route exact path="/login" component={LoginComponent} />
          <Route path="/home" component={HomeComponent} />
          <Route exact path="/" component={IntroductionComponent} />
          <Route path="/*" component={() => <Redirect to="/intro" />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
