import React, { Component } from "react";
import uniquid from "uniqid";

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import {
  DoNotRefresh,
  Initializing,
  PleaseWait,
  ProcessFailed,
  UnexpectedError,
} from "../../config/CommonConstants";
import LabelComponent from "../../reusables/info/LabelComponent";
import SmallComponent from "../../reusables/info/SmallComponent";
import TextInputComponent from "../../reusables/inputs/TextInputComponent";

export default class SignUpComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        firstName: "",
        lastName: "",
        password: "",
      },
      onboardingSession: this.props.location.onboardingSession,
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
      opacity: 1,
      reload: false,
    };
    this.checkIfThereIsOnGoingSession();
    this.checkAuthorization();
  }

  checkIfThereIsOnGoingSession = () => {
    if (localStorage.getItem("onboardingSession"))
      this.props.history.replace("/onboarding-questionnaire");
  };

  checkAuthorization = () => {
    if (!this.props.location.auth) return this.props.history.replace("/intro");
  };

  unsetBusyState = async () => {
    if (this.state.reload) return window.location.reload();
    return this.setState({ showLoading: false, opacity: 1, redirect: "" });
  };

  setName = (key, value) => {
    let { customer } = this.state;
    customer[key] = value;
    return this.setState({ customer: customer });
  };

  setPassword = (e) => {
    let {
      customer: { password },
    } = this.state;
    let regex = /\s/;
    if (!regex.exec(e.target.value)) {
      password = e.target.value;
      return this.setState({ customer: { ...this.state.customer, password } });
    }
  };

  registerUser = async (e) => {
    try {
      e.preventDefault();

      if (!this.state.customer.password.trim().length)
        throw new Error("Fill password to sign up !");

      if (this.state.customer.password.trim().length < 5)
        throw new Error("Password must be of at least 5 characters !");

      this.setState({
        loadingTitle: PleaseWait,
        loadingDescription: Initializing,
        showLoading: true,
        showLoadingSpinner: true,
        showCloseButton: false,
        extraInfo: DoNotRefresh,
        opacity: 0.3,
      });

      let { customer, onboardingSession } = this.state;
      let {
        code,
        message,
        result: data,
      } = await postRequest(
        "/escape/insert",
        {
          payload: {
            ...customer,
            ...onboardingSession,
            onboardingSessionId: onboardingSession._id,
          },
          options: { uri: "/sign-up" },
        },
        uniquid()
      );
      if (code > 300) throw new Error(message);

      if (data.token) localStorage.setItem("token", data.token);

      return this.props.history.push({
        pathname: "/home/profile",
        onboardingSession: data,
      });
    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
        opacity: 0.3,
      });
    }
  };

  validateForm = () => {
    let {
      customer: { firstName, password },
      onboardingSession: { _id, nickname },
    } = this.state;
    if (
      nickname.length &&
      firstName.length &&
      password.length &&
      password.length >= 5 &&
      _id
    ) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
            <div className="container-fluid bg-light p-3 mt-5 rounded">
              <div className="text-center">
                <img src="/assets/logo.webp" alt="" />
              </div>
              <p className="display-4 text-center font-weight-bold mt-3">
                Hi! I'm Wysa
              </p>
              <p className="text-muted text-center font-weight-bold mt-1">
                Great, you have completed your assessment, we are processing
                results meanwhile choose your password to login later for more
                details.
              </p>
              <div className="text-left">
                <form onSubmit={this.registerUser}>
                  <div className="form-row mt-3">
                    <div className="form-group col-md-12">
                      <div className="form-row col-md-6">
                        <LabelComponent
                          htmlFor="name"
                          classes="font-weight-bold"
                          labelContent="First Name *"
                        />

                        <TextInputComponent
                          autoComplete="nope"
                          classes="form-control"
                          id="firstName"
                          areaDescribedby="firstName"
                          placeholder="Your First Name *"
                          pipeToParent={this.setName}
                          field="firstName"
                          allowSpaces={false}
                          titleCase={true}
                          required={true}
                        />

                        <SmallComponent
                          id="firstName"
                          classes="form-text font-weight-bold text-danger"
                          content="First Name cannot contain spaces !"
                        />
                      </div>
                      <div className="form-row col-md-6">
                        <LabelComponent
                          htmlFor="name"
                          classes="font-weight-bold"
                          labelContent="Last Name"
                        />

                        <TextInputComponent
                          autoComplete="nope"
                          classes="form-control"
                          id="lastName"
                          areaDescribedby="lastName"
                          placeholder="Your Last Name"
                          pipeToParent={this.setName}
                          field="lastName"
                          allowSpaces={false}
                          titleCase={true}
                          required={false}
                        />

                        <SmallComponent
                          id="lastName"
                          classes="form-text font-weight-bold text-danger"
                          content="Last Name cannot contain spaces !"
                        />
                      </div>
                      <div className="form-row col-md-6">
                        <label htmlFor="loginPassword">Your Password *</label>
                        <input
                          type="password"
                          className="form-control"
                          id="loginPassword"
                          placeholder="Fill Your Password"
                          value={this.state.customer.password}
                          onChange={this.setPassword}
                        />
                        <small
                          id="passwordError"
                          className="form-text text-danger font-weight-bold h6"
                        >
                          Password cannot contain spaces and it must be of 5
                          chars at least !
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    {this.state.onboardingSession ? (
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn display-4 px-4 py-4 mt-4 rounded-circle"
                          style={{
                            backgroundColor: "rgb(243,213,93,1)",
                            display: !this.validateForm() ? "inline" : "none",
                          }}
                          disabled={this.validateForm()}
                        >
                          <i
                            className="fa-solid fa-arrow-right"
                            style={{ fontSize: "2rem" }}
                          ></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    );
  }
}
