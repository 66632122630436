import React, { Component } from "react";
import uniquid from "uniqid";

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import {
    DoNotRefresh,
    PleaseWait,
    ProcessFailed,
    UnexpectedError,
    TokenExpired,
} from "../../config/CommonConstants";

import NavbarComponent from "../NavbarComponent";
import FooterComponent from "../FooterComponent";


export default class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assessment: null,
        };
    }

    unsetBusyState = async () => {
        if (this.state.redirect && this.state.redirect.length) {
            localStorage.clear();
            return this.props.history.replace(this.state.redirect);
        }
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };


    getAssessmentDetails = async () => {
        try {

            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: 'Getting Your Assessment Details, It May Take A While !',
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3,
            });

            let {
                result: data,
                code,
                message,
            } = await postRequest(
                "/web/user/query",
                {
                    payload: { token: localStorage.getItem('token') },
                    options: { uri: "/get-assessment" },
                },
                uniquid()
            );
            if (code && code !== 200) {
                localStorage.clear();
                return this.setState({
                    showLoading: true,
                    loadingTitle: ProcessFailed,
                    loadingDescription: message,
                    extraInfo: TokenExpired,
                    showLoadingSpinner: false,
                    showCloseButton: true,
                    redirect: "/intro",
                });
            }

            return this.setState({
                assessment: data,
                showLoading: false,
                loadingTitle: ProcessFailed,
                loadingDescription: message,
                extraInfo: TokenExpired,
                showLoadingSpinner: false,
                opacity: 1,
                showCloseButton: true,
            });
        } catch (error) {
            return this.setState({
                showLoading: true,
                loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
            });
        }
    };

    componentDidMount() {
        return this.getAssessmentDetails();
    }

    logOut = () => {
        localStorage.clear();
        return this.props.history.replace("/intro");
    }

    processAnswer = (question) => {
        switch (question.type) {
            case "time": {
                return (
                    <>
                        {question.answer}
                    </>
                );
            }
            case "selection": {
                return (
                    <>
                        {question.answer.label}
                    </>
                );
            }
            case "multi-selection": {
                let ans = question.answer.map((an) => an.label);
                return (
                    <>
                        {ans.join()}
                    </>
                );
            }

            default: {
                return <></>
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <NavbarComponent user={this.state.user} />
                    <div style={{ position: "relative", top: "4rem" }}>
                        <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
                            <div className="container-fluid bg-light p-3 rounded">
                                {this.state.assessment ? (
                                    <div className="card w-50 mx-auto">
                                        <div className="card-body">
                                            <h5 className="card-title">{this.state.assessment.name}</h5>
                                            <p className="card-text">Your assessment was completed on <span className="font-weight-bold">{new Date(this.state.assessment.onboardingSessionId.updatedAt).toDateString('en-IN')}</span>.</p>
                                            <p className="card-text">Below are the details of your assessment.</p>
                                            {this.state.assessment.onboardingSessionId.answers.map((question) => {
                                                return <div key={question._id}>
                                                    <p className="font-weight-bold">{question.question}</p>
                                                    <p className="text-muted mx-1">- {this.processAnswer(question)}</p>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent />
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        );
    }
}
