import React, { Component } from 'react'
import uniquid from "uniqid";

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import { DoNotRefresh, Initializing, PleaseWait, ProcessFailed, UnexpectedError } from '../../config/CommonConstants';
import LabelComponent from '../../reusables/info/LabelComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';

export default class OnboardingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        nickname: '',
      },
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
      opacity: 1,
      reload: false,
    }
    this.checkIfThereIsOnGoingSession();
    this.checkAuthorization();
  }

  checkAuthorization = () => {
    if (!this.props.location.auth)
      return this.props.history.replace('/intro');
  }

  checkIfThereIsOnGoingSession = () => {
    if (localStorage.getItem("onboardingSession"))
      this.props.history.replace("/onboarding-questionnaire");
  }

  unsetBusyState = async () => {
    if (this.state.reload)
      return window.location.reload();
    return this.setState({ showLoading: false, opacity: 1, redirect: "" });
  };

  setNickname = (key, value) => {
    let { customer } = this.state;
    customer[key] = value;
    return this.setState({ customer: customer });
  }

  initiateOnboarding = async (e) => {
    try {
      e.preventDefault();

      if (!this.state.customer.nickname.trim().length)
        throw new Error("Fill nickname to start the onboarding process !");

      this.setState({
        loadingTitle: PleaseWait,
        loadingDescription: Initializing,
        showLoading: true,
        showLoadingSpinner: true,
        showCloseButton: false,
        extraInfo: DoNotRefresh,
        opacity: 0.3
      });

      let { customer } = this.state;
      let { code, message, result: { data } = {} } = await postRequest("/escape/insert", { payload: { ...customer }, options: { uri: "/initialize-onboarding-session" } }, uniquid());
      if (code > 300)
        throw new Error(message);

      localStorage.setItem('onboardingSession', JSON.stringify(data));
      this.setState({
        loadingTitle: '',
        loadingDescription: '',
        showCloseButton: false,
        reload: false,
        showLoadingSpinner: false,
        showLoading: false,
        opacity: 1,
        redirect: ""
      });

      return this.props.history.push({
        pathname: "/onboarding-questionnaire",
        auth: true,
        onboardingSession: data
      });

    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
        opacity: 0.3
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
            <div className="container-fluid bg-light p-3 mt-5 rounded">
              <div className='text-center'>
                <img src='/assets/logo.webp' />
              </div>
              <p className='display-4 text-center font-weight-bold mt-3'>Hi! I'm Wysa</p>
              <p className='text-muted text-center font-weight-bold mt-1'>Our conversations are private and anonymous, so there is no login.<br /> Just choose a nickname and we are good to go.</p>
              <div className='text-left'>
                <form onSubmit={this.initiateOnboarding}>
                  <div className="form-row mt-5">
                    <div className="form-group col-md-12">
                      <div className='row w-50 mx-auto'>
                        <LabelComponent
                          htmlFor="name"
                          classes="font-weight-bold"
                          labelContent="Choose Nickname *"
                        />

                        <TextInputComponent
                          autoComplete="nope"
                          classes="form-control"
                          id="nickname"
                          areaDescribedby="nickname"
                          placeholder="Your Nickname"
                          pipeToParent={this.setNickname}
                          field="nickname"
                          allowSpaces={false}
                          titleCase={true}
                          required={true}
                        />

                        <SmallComponent
                          id="nickname"
                          classes="form-text font-weight-bold text-danger"
                          content="Nickname cannot contain spaces !"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="col-12 text-center">
                      <button
                        type='submit'
                        className='btn display-4 px-4 py-4 mt-4 rounded-circle'
                        style={{
                          backgroundColor: 'rgb(243,213,93,1)',
                          display: this.state.customer.nickname.length ? 'inline' : 'none'
                        }}
                        disabled={this.state.customer.nickname.length ? false : true}>
                        <i className="fa-solid fa-arrow-right" style={{ fontSize: '2rem' }}></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    )
  }
}
