import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavbarComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <NavLink className="navbar-brand font-weight-bolder text-info" to="/home">
            <img src='/assets/logo.webp' height={"40px"} alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto mx-2">
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/profile">Profile</NavLink>
              </li>
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/assessment">Assessment</NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default NavbarComponent;
