import React, { Component } from 'react'

export default class IntroductionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.checkIfThereIsOnGoingSession();
        this.checkIfLoggedIn();
    }

    checkIfThereIsOnGoingSession = () => {
        if (localStorage.getItem("onboardingSession"))
            return this.props.history.replace("/onboarding-questionnaire");
    }

    checkIfLoggedIn = () => {
        if (localStorage.getItem("token"))
            return this.props.history.replace("/home/profile");
    }

    navigateToOnboarding = () => {
        return this.props.history.push({
            pathname: "/onboarding",
            auth: true,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
                        <div className="container-fluid bg-light p-3 mt-5 rounded">
                            <div className='text-center'>
                                <img src='/assets/logo.webp' alt=""/>
                            </div>
                            <p className='display-4 text-center font-weight-bold mt-3'>Hi! I'm Wysa</p>
                            <p className='text-muted text-center font-weight-bold mt-1'>I'm Here To Help You Sleep Better</p>
                            <div className='text-center'>
                                <button
                                    className='btn display-4 px-4 py-4 m-5 rounded-circle'
                                    style={{ backgroundColor: 'rgb(243,213,93,1)' }}
                                    onClick={this.navigateToOnboarding}>
                                    <i className="fa-solid fa-arrow-right" style={{ fontSize: '2rem' }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
