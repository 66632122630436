import React, { Component } from "react";
import uniquid from "uniqid";

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import {
  DoNotRefresh,
  PleaseWait,
  ProcessFailed,
  ProcessingOnboarding,
  UnexpectedError,
} from "../../config/CommonConstants";
import LabelComponent from "../../reusables/info/LabelComponent";
import SmallComponent from "../../reusables/info/SmallComponent";
import SelectInputComponent from "../../reusables/inputs/SelectInputComponent";

export default class QuestionnaireComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      answer: null,
      rawTime: "",
      onboardingSession:
        this.props.location.onboardingSession ||
        JSON.parse(localStorage.getItem("onboardingSession")),
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
      opacity: 1,
      reload: false,
    };
    this.checkAuthorization();
  }

  checkAuthorization = () => {
    if (!this.props.location.auth && !localStorage.getItem("onboardingSession"))
      return this.props.history.replace("/intro");
  };

  unsetBusyState = async () => {
    if (this.state.reload) return window.location.reload();
    return this.setState({ showLoading: false, opacity: 1, redirect: "" });
  };

  submitAnswer = async (e) => {
    try {
      e.preventDefault();

      if (!this.state.answer)
        throw new Error(
          "Answer is required to continue the onboarding process !"
        );

      this.setState({
        loadingTitle: PleaseWait,
        loadingDescription: ProcessingOnboarding,
        showLoading: true,
        showLoadingSpinner: true,
        showCloseButton: false,
        extraInfo: DoNotRefresh,
        opacity: 0.3,
      });

      let { question, answer, onboardingSession } = this.state;
      let {
        code,
        message,
        result: data = {},
      } = await postRequest(
        "/escape/insert",
        {
          payload: {
            question,
            answer,
            ...onboardingSession,
            onboardingSessionId: onboardingSession._id,
          },
          options: { uri: "/continue-onboarding-session" },
        },
        uniquid(),
      );

      if (code > 300) throw new Error(message);

      if (data.hasNext) {
        return this.setState({
          loadingTitle: "",
          loadingDescription: "",
          showCloseButton: false,
          reload: false,
          showLoadingSpinner: false,
          showLoading: false,
          opacity: 1,
          redirect: "",
          question: data.nextQuestion,
          answer: null,
          rawTime: "",
        });
      }

      localStorage.removeItem("onboardingSession");
      this.setState({
        showLoading: false,
        opacity: 1,
      });

      return this.props.history.push({
        pathname: "/sign-up",
        auth: true,
        onboardingSession: this.state.onboardingSession,
      });
    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
        opacity: 0.3,
      });
    }
  };

  fetchNextQuestion = async () => {
    try {
      this.setState({
        loadingTitle: PleaseWait,
        loadingDescription: ProcessingOnboarding,
        showLoading: true,
        showLoadingSpinner: true,
        showCloseButton: false,
        extraInfo: DoNotRefresh,
        opacity: 0.3,
      });

      let {
        result: data,
        code,
        message,
      } = await postRequest(
        "/escape/insert",
        {
          payload: { onboardingSessionId: this.state.onboardingSession._id },
          options: { uri: "/continue-onboarding-session" },
        },
        uniquid(),
      );

      if (data && data.functions && data.functions.length) {
        for (const func of data.functions)
          try {
            // eslint-disable-next-line
            eval(func)();
          } catch (e) {
            // TODO handle error
          }
      }

      if (code > 300) throw new Error(message);

      if (!data.hasNext) {
        localStorage.removeItem("onboardingSession");
        this.setState({
          showLoading: false,
          opacity: 1,
        });

        return this.props.history.push({
          pathname: "/sign-up",
          auth: true,
          onboardingSession: this.state.onboardingSession,
        });
      }

      return this.setState({
        question: data.nextQuestion,
        answer: null,
        rawTime: "",
        showLoading: false,
        opacity: 1,
      });
    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
        opacity: 0.3,
      });
    }
  };

  setAnswer = (key, value) => {
    return this.setState({ [key]: value });
  };

  convertTo12HrFormat = (time) => {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  handleTime = (e) => {
    const state = { ...this.state };
    state.rawTime = e.target.value;
    state.answer = this.convertTo12HrFormat(e.target.value);
    return this.setState({ ...state });
  };

  handleMultipleInput = (e) => {
    let { question, answer } = this.state;
    const { options } = question;
    if (!answer) answer = [];

    if (e.target.checked) {
      let selected = options.filter((object) => object._id === e.target.value);
      answer.push(...selected);
    } else {
      answer = answer.filter((object) => object._id !== e.target.value);
    }

    if (!answer.length) answer = null;
    this.setAnswer("answer", answer);
  };

  renderQuestionEditor = () => {
    switch (this.state.question.type) {
      case "time": {
        return (
          <>
            <input
              type={"time"}
              onChange={this.handleTime}
              value={this.state.rawTime}
            />
            <p className="text-muted font-weight-bold mt-1 ">
              {this.state.answer
                ? `Time in 12 Hour Format :- ${this.state.answer}`
                : ""}
            </p>
          </>
        );
      }
      case "selection": {
        return (
          <>
            <LabelComponent
              htmlFor="answer"
              classes="font-weight-bold"
              labelContent=""
            />

            <SelectInputComponent
              autoComplete="nope"
              classes="form-control"
              id="answer"
              areaDescribedby="answerId"
              pipeToParent={this.setAnswer}
              field="answer"
              isObject={true}
              defaultOption="--Select--"
              displayValue="label"
              array={this.state.question.options}
            />

            <SmallComponent
              id="answerId"
              classes="form-text font-weight-bold text-danger"
              content="Select Single Choice !"
            />
          </>
        );
      }
      case "multi-selection": {
        return (
          <>
            <LabelComponent
              htmlFor="answer"
              classes="font-weight-bold"
              labelContent="Your Answer *"
            />
            <div className="form-row">
              {this.state.question.options.map((answer) => {
                return (
                  <div className="col-lg-6 py-1" key={JSON.stringify(answer)}>
                    <input
                      type={"checkbox"}
                      value={answer._id}
                      id={answer._id}
                      self={JSON.stringify(answer)}
                      key={answer._id}
                      onChange={this.handleMultipleInput}
                    />
                    <label className="pl-1" htmlFor={answer._id}>
                      {answer.label}
                    </label>
                  </div>
                );
              })}
            </div>
            <SmallComponent
              id="answerId"
              classes="form-text font-weight-bold text-danger"
              content={"You can choose multiple answers for this questions !"}
            />
          </>
        );
      }
      default: {
        return <></>
      }
    }
  };

  componentDidMount() {
    this.fetchNextQuestion();
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
            <div className="container-fluid bg-light p-3 mt-5 rounded">
              {this.state.question ? (
                <div>
                  <p className="text-left font-weight-bold m-0 p-0 mt-1">
                    {this.state.question.question}
                  </p>
                  <p className="text-muted text-left m-0 p-0">
                    {this.state.question.hint || ""}
                  </p>
                  <div className="text-left">
                    <form onSubmit={this.submitAnswer}>
                      <div className="form-row mt-3">
                        <div className="form-group col-md-12">
                          <div className="form-row w-50">
                            {this.renderQuestionEditor()}
                          </div>
                        </div>
                      </div>
                      <div className="py-4">
                        <div className="col-12 text-center">
                          <button
                            type="submit"
                            className="btn display-4 px-4 py-4 mt-4 rounded-circle"
                            style={{
                              backgroundColor: "rgb(243,213,93,1)",
                              display: this.state.answer ? "inline" : "none",
                            }}
                            disabled={this.state.answer ? false : true}
                          >
                            <i
                              className="fa-solid fa-arrow-right"
                              style={{ fontSize: "2rem" }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    );
  }
}
