import React, { Component } from 'react';

class SelectInputComponent extends Component {

    handleOneChange = (e) => {
        let value = e.target.value;
        let { isObject, pipeToParent, field } = this.props;

        if (value === "null")
            return pipeToParent(field, undefined)

        if (isObject) {
            let { array = [] } = this.props;
            for (let i = 0; i < array.length; i++) {
                if (value === array[i]._id)
                    if (pipeToParent)
                        return pipeToParent(field, array[i])
            }
        } else {
            return pipeToParent(field, value)
        }
        return pipeToParent(field, undefined)
    }

    render() {
        let { defaultOption, classes, id, isObject, style, autoComplete, array = [], required = false, areaDescribedby, displayValue = "name" } = this.props;
        return (
            <select
                className={classes}
                id={id}
                style={style}
                required={required}
                autoComplete={autoComplete}
                onChange={this.handleOneChange}
                areadescribedby={areaDescribedby}
            >
                <option value="null">{defaultOption}</option>
                {isObject ?
                    array.map((v) => {
                        return <option value={v._id} key={v._id} self={JSON.stringify(v)}>{v[displayValue]}</option>
                    }) :
                    array.map((v) => {
                        return <option value={v} key={v} self={v}>{v}</option>
                    })
                }
            </select>
        );
    }
}

export default SelectInputComponent;