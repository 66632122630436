module.exports = {
  development: {
    API_ENDPOINT: "http://localhost:7201",
    ENV: "development",
  },
  production: {
    API_ENDPOINT: "https://api-dot-wysa-private.pawanbishnoi.dev",
    ENV: "production",
  },
};
